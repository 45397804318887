// Main data for academic_CV
export const DATA = "DATA";
export const MENU = "MENU";
export const STAGES = "STAGES";
export const ACADEMIC_TOGGLE = "ACADEMIC_TOGGLE";
export const ACADEMIC_HOVER = "ACADEMIC_HOVER";
export const SCROLLABLE_REF = "SCROLLABLE_REF";
export const VISIBILITY = "VISIBILITY";
export const ACADEMIC_ELEMENT_SIZE = "ACADEMIC_ELEMENT_SIZE";
export const CURRENT_PAGE = "CURRENT_PAGE";
